<template>
  <div v-if="isshowgrid">
    <va-card title="Direct Play List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Provider</span>
            <va-select              
              v-model="direct_play.provider"
              textBy="id"
              searchable
              :options="provider_obj"
              :error="!!providerObjErrors.length"
              :error-messages="providerObjErrors"
            />
            <span class="va-form-label va-form-required-label">Context</span>
            <va-input
              v-model="direct_play.context"
              type="text"              
              placeholder="Enter Context"
              :error="!!contextErrors.length"
              :error-messages="contextErrors"
            />
            <div class="flex xs12">
              <span class="va-form-label va-form-required-label">Direct play config template</span>              
              <textarea v-if="isCreate || (isUpdate && editFlag)" class="text_box" v-model="direct_play.play_template" />
              <textarea v-if="!editFlag && isUpdate" class="text_box" v-model="direct_play.play_template" :disabled ="true" />
            </div>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createPlay()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updatePlay()">Update</va-button>              
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'direct_play',
  created () {
     this.getAllPlay()
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
	   //  this.$http.get(config.menu.host + '/direct_play').then(response => {
	   //    loader.hide()
	   //    this.playData = response.body
	   //    this.isshowForm = false
	   //    this.isshowgrid = true
    //     this.getAllPlay()
	   //  }, error => {
	   //    loader.hide()
	   //  })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      editFlag: true,
      provider_obj: [],
      isUpdate: false,
      showRemoveModal: false,
      direct_play: {
        provider: '',
        context: '',
        play_template: '',
      },
      order: '',
      title: '',
      playData: [],
      providerObjErrors: [],
      contextErrors: [],
    }
  },
  computed: {
    formReady () {
      return !this.providerObjErrors.length && !this.contextErrors.length
    },
    fields () {
      return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
      },{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'provider',
        title: 'Provider',
      },
      {
        name: 'context',
        title: 'Context',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.playData)
    },
  },
  methods: {
	    getAllPlay () {
	      this.$http.get(config.menu.host + '/direct_play').then(response => {
          let index = 0
          this.playData = response.body.map(function (item) {
              item.id = index++
              item.sno = index
              item.is_existing = true
              item.checkbox_value = false
              return item
          })
          this.isshowForm = false
	        this.isshowgrid = true
	      })
	    },
    getAllproviders () {
      this.$http.get(config.menu.host + '/provider').then(response => {
        var name_array = []
        if (response.body.length > 0) {
          this.provider_info = (response.body.length > 0) ? response.body : []
          response.body.map(function (item) {
            name_array.push(item.brand)
          })
        } else {
          name_array = []
        }
        this.provider_obj = name_array
      })
    },
    createPlay () {
      this.providerObjErrors = this.direct_play.provider ? [] : ['Provider is required']
      this.contextErrors = this.direct_play.context ? [] : ['Context is required']
      if (!this.formReady) {
        return
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/direct_play', this.direct_play).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updatePlay () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/direct_play/' + this.direct_play_id, this.direct_play).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
	  add () {
      this.getAllproviders()
	    this.title = 'Create Direct Play'
      this.direct_play.provider = ''
      this.direct_play.context = ''
      this.direct_play.play_template = ''
      this.providerObjErrors = []
      this.contextErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
	    },
    edit (row) {
      this.title = 'Update Direct play'
      this.direct_play_id = row.direct_play_id
      this.direct_play.provider = row.provider
      this.direct_play.context = row.context
      this.direct_play.play_template = row.play_template
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
	    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllPlay()
    },
	    search: debounce(function (term) {
	    	this.term = term
	    }, 400),
  },
}
</script>

<style scoped>
.text_box {
  width: 937px;
  height: 101px;
  border-color: black;
  border-radius: 10px;
  resize: none;
}
</style>
